@use '../../../variables';
@use '../../../fonts';
@use '../../../colors';

.option {
  padding: variables.$base-spacing * 2 variables.$base-spacing * 3;
  font-family: fonts.$font-family-regular;
  color: colors.$dark-blue;
  cursor: default;

  &.highlight {
    background: colors.$very-light-blue;
  }

  &.selected {
    color: colors.$light-blue;

    &.highlight {
      color: colors.$dark-blue;
    }
  }
}
