@use '../../../colors';
@use '../../../variables';
@use '../../../fonts';

$color-background: colors.$white;
$color-background-leading-text: colors.$lighter-grey;

.timeInputContainer {
  display: flex;
  align-items: stretch;
  border: variables.$border;
  border-radius: variables.$border-radius;
  background-color: $color-background;

  &:focus-within {
    border-color: colors.$very-light-blue;
    outline: variables.$default-outline;
  }

  &.error {
    border-color: colors.$red-new;
  }

  .leadingText {
    display: flex;
    align-items: center;
    padding: 0 2 * variables.$base-spacing;
    background-color: $color-background-leading-text;
    font-family: fonts.$font-family-regular;
  }

  .timeInput {
    width: 75px;
    border: none;
    background-color: $color-background;
  }
}
