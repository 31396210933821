@use '../../breakpoints';
@use '../../accessibility';
@use '../../colors';
@use '../../variables';
@import '../../z-indices';

$dropper-background: colors.$white;
$dropper-arrow-size: 5px;

.dropoutDropper {
  box-sizing: border-box;
  transition: all 0.2s;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: $dropoutIndex;
  opacity: 1;
  padding: variables.$base-spacing * 4 variables.$base-spacing * 7;
  width: 100vw;
  filter: drop-shadow(0 0 1px colors.$light-grey);
  color: colors.$dark-blue;
  background: $dropper-background;

  @media screen and (min-width: breakpoints.$sm-min) {
    max-width: 14rem;
    min-width: max-content;
  }

  &::before {
    content: '';
    position: absolute;
    top: -$dropper-arrow-size;
    right: variables.$base-spacing * 4;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid $dropper-background;
    width: 0;
    height: 0;
  }

  &:not(.isOpen) {
    @include accessibility.visually-hidden;
    opacity: 0;
  }
}
