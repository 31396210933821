@use '../../colors';
@use '../../fonts';
@use '../../breakpoints';

.form {
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: 20rem 1fr;

  @media screen and (max-width: breakpoints.$sm-max) {
    grid-template-columns: 1fr;
  }

  .innerForm {
    .section {
      $minGroupWidth: 19rem;

      display: grid;
      margin: 2rem;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1rem;

      @media screen and (max-width: breakpoints.$md-max) {
        grid-template-columns: repeat(auto-fill, minmax($minGroupWidth, 1fr));
      }

      &Wide {
        grid-template-columns: 1fr;
      }

      .group {
        display: grid;
        grid-column-gap: 1rem;
        grid-template-columns: 1fr 1fr;

        &Bollards {
          min-width: 15rem;
        }
        &Time {
          min-width: 19rem;
        }
      }
    }
  }

  .settings {
    padding: 1.5rem 1.25rem;
    background-color: colors.$lighter-grey;
  }

  .title {
    margin-top: 0;
    font-family: fonts.$font-family-strong;
    font-size: fonts.$font-size-medium;
  }

  .content {
    font-family: fonts.$font-family-regular;
    font-size: fonts.$font-size-regular;
  }
}

.button {
  border-radius: 1px;
  color: colors.$dark-blue;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;

  &Cta {
    border-color: colors.$yellow;
    background: colors.$yellow;

    &:hover {
      background: colors.$yellow;
    }
  }
  &Outlined {
    border-color: colors.$dark-blue;
    background: none;

    &:hover {
      background: none;
    }
  }
}

.toggleContainer {
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: auto;
  font-size: fonts.$font-size-regular;
  font-family: fonts.$font-family-strong;
  color: colors.$cloud-grey;

  &Active {
    color: colors.$dark-blue;
  }

  .toggle {
    width: 36px;
    margin-left: 0.75rem;
  }
}

.statusSelectContainer {
  margin-top: 2rem;
  max-width: 12rem;
}
