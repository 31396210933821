@use '../../accessibility';
@use '../../breakpoints';
@use '../../fonts';
@use '../../colors';
@use '../../variables';

$cell-spacing: variables.$base-spacing * 2; // 4 * 2 = 8px

.tableRow {
  td {
    padding: $cell-spacing * 2;
    vertical-align: top;
  }

  &:hover {
    background-color: colors.$very-light-blue-hover;
    cursor: pointer;
  }

  &:not(:hover) {
    .editButton {
      @include accessibility.visually-hidden-until-focus;
    }
  }

  .editButton {
    display: block;
    vertical-align: top;
    margin: 0 0 0 variables.$base-spacing * 7;
    border-radius: 10px;
    padding: 0 variables.$base-spacing * 2;
    background-color: colors.$very-light-blue;

    &:focus,
    &:hover {
      background-color: colors.$dark-blue;
    }
  }

  .editButtonText {
    font-size: 12px;
    font-weight: normal;
    font-family: fonts.$font-family-strong;
    color: colors.$white;
    line-height: 1.75;
    text-transform: uppercase;
  }
}

.cellLayByStatus {
  $iconSize: 0.625rem;
  $spacing: 0.375rem;

  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 0.125rem;

  .status {
    display: flex;
    align-items: center;
    transform: translateY(-1px);
    margin-left: $spacing;

    &Icon {
      display: block;
      width: $iconSize;
      height: $iconSize;
      background-color: colors.$apple;
      border-radius: 5px;
      margin-right: $spacing;
      flex-shrink: 0;

      &Booked {
        background-color: colors.$chestnut-rose;
      }
      &TentativelyBooked {
        background-color: colors.$sea-buckthorn;
      }
    }
    &Text {
      font-family: fonts.$font-family-regular;
      font-size: fonts.$fs-12;
      line-height: 1.2;
    }
  }
}

.cellLabel {
  color: colors.$dark-grey;
}

.cellShipName {
  font-family: fonts.$font-family-strong;
  line-height: 1;
}

.cellShipNameInner {
  @media screen and (min-width: breakpoints.$lg-min) {
    min-height: 3rem;
  }
}

.cellRemarkHeader {
  font-family: fonts.$font-family-strong;
  color: colors.$red-new;
}

@media screen and (max-width: breakpoints.$sm-max) {
  .tableRow {
    display: grid;
    padding-top: $cell-spacing;
    padding-bottom: $cell-spacing;

    td {
      padding-top: $cell-spacing;
      padding-bottom: $cell-spacing;
    }
  }
}

@media screen and (min-width: breakpoints.$sm-min) and (max-width: breakpoints.$sm-max) {
  .tableRow {
    grid-template: auto / repeat(2, 1fr);

    td:first-child {
      grid-column: 1 / 3;
    }

    .cellRemark {
      grid-column: 1 / 3;
    }
  }
}

@media screen and (min-width: breakpoints.$md-min) {
  .tableRow {
    border-top: 1px solid colors.$lighter-grey;

    td {
      padding: $cell-spacing * 2;
    }

    td:first-child {
      border-right: 1px solid colors.$lighter-grey;
    }
  }
}

@media screen and (min-width: breakpoints.$md-min) and (max-width: breakpoints.$md-max) {
  .tableRow {
    display: grid;
    grid-template: repeat(2, 1fr) / 20% repeat(5, 1fr);

    td:first-child {
      grid-row: 1 / 4;
    }

    .cellRemark {
      grid-column: 2 / 7;
    }
  }
}

@media screen and (min-width: breakpoints.$lg-min) {
  .tableRow {
    display: grid;
    grid-template-columns: 20% repeat(10, 1fr);

    td {
      padding: $cell-spacing * 2.5 $cell-spacing * 2;
    }

    .cellShipName {
      padding-left: $cell-spacing * 2.5;
      grid-row: 1 / 3;
    }

    .cellDeparture {
      padding-left: $cell-spacing * 2.5;
    }

    .cellRemark {
      grid-column: 2 / 12;
    }
  }
}
