@use '../../../colors';
@use '../../../fonts';
@use 'toggleTheme';

.toggle {
  @include toggleTheme.toggleTheme(colors.$dark-grey-new, colors.$light-blue, colors.$white, colors.$white, colors.$white, colors.$white, colors.$disable-grey-new);
}

.toggleGreen {
  @include toggleTheme.toggleTheme(colors.$dark-grey-new, colors.$green, colors.$white, colors.$white, colors.$white, colors.$white, colors.$disable-grey-new);
}
