@use '../../z-indices.scss';
@use '../../colors.scss';
@use '../../breakpoints.scss';

$modal-max-width: breakpoints.$maxPageWidth;

.modalParent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: z-indices.$planningEntryModalIndex - 1;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(colors.$black, 0.6);
}

.modal {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  margin: 0 auto auto;
  width: auto;
  max-width: $modal-max-width;
  max-height: 100vh;

  cursor: auto;
  background-color: colors.$white;
  z-index: z-indices.$planningEntryModalIndex;
}
