@use '../../colors';
@use '../../fonts';
@use '../../variables';

.label {
  display: inline-block;
  border-radius: 3px;
  padding: 0 variables.$base-spacing;
  text-transform: uppercase;
  font-family: fonts.$font-family-strong;
  color: colors.$white;
  background-color: colors.$label-estimate;

  &Actual {
    background-color: colors.$label-actual;
  }
  &Date {
    background-color: colors.$light-blue;
  }
}

.value {
  font-family: fonts.$font-family-strong;
  white-space: nowrap;
}

.valueUnknown {
  font-family: fonts.$font-family-regular;
  color: colors.$dark-grey;
}
