@use '../../variables';
@use '../../colors';
@use '../../z-indices';

$side-menu-width: 375px;
$side-menu-background-color: colors.$white;
$side-menu-color: colors.$dark-blue;

.sideMenuOverlay {
  z-index: z-indices.$actionPanelIndex;

  &:not(.active) {
    display: none;
  }

  &.active {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(black, 0.2);
    cursor: pointer;
  }
}

.sideMenu {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: z-indices.$actionPanelIndex;

  transform: translateX(-100%);
  width: calc(100% - #{variables.$primary-menu-height});
  max-width: $side-menu-width;
  overflow: hidden;

  transition: transform 0.3s ease-out;

  background-color: $side-menu-background-color;
  color: $side-menu-color;

  &.active {
    transform: translateX(0);
    box-shadow: 10px 0px 18px rgba(black, 0.2);
  }
}

.menuContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: variables.$base-spacing * 4;
  height: calc(100% - #{variables.$primary-menu-height});
}
