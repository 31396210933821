@use '../../../accessibility';
@use '../../../variables';
@use '../../../colors';

.checkBox {
  &:checked + .checkBoxLabel::before {
    border-color: colors.$light-blue;
    background: colors.$light-blue;
  }

  &:checked:hover + .checkBoxLabel::before,
  &:checked:focus + .checkBoxLabel::before {
    border-color: colors.$dark-blue;
    background: colors.$dark-blue;
  }

  &:hover + .checkBoxLabel::before,
  &:focus + .checkBoxLabel::before {
    border-color: colors.$light-blue;
  }
}

.checkBoxLabel {
  position: relative;

  &::before {
    content: '';
    display: inline-block;
    margin-right: variables.$base-spacing * 2;
    border: 2px solid colors.$light-grey;
    min-width: 1rem;
    width: 1rem;
    height: 1rem;
    background: colors.$white;
  }
}

.checkBoxLabelInner {
  position: relative;
  display: inline-block;
  line-height: 1.25rem;
}

.checkboxIcon {
  position: absolute;
  left: 0;
  fill: colors.$white;
}
