@use '../../breakpoints';
@use '../../variables';
@use '../../colors';
@use '../../fonts';

.noVisitsContainer {
  padding: variables.$base-spacing * 2 variables.$base-spacing * 5;
  min-height: 50vh;
  background-color: colors.$white;

  > * {
    margin: 0;

    + * {
      margin-top: 0.75rem;
    }
  }

  h1 {
    font-family: fonts.$font-family-medium;
    font-size: fonts.$font-size-large;
  }

  @media screen and (min-width: breakpoints.$sm-min) {
    padding: variables.$base-spacing * 5;
  }
}

.addVesselButton {
  transition: all 0.15s;
  border: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  text-decoration: underline;
  background: none;

  &:focus,
  &:hover {
    color: colors.$light-blue;
  }
}
