@use '../../overlay';
@use '../../variables';
@use '../../fonts';
@use '../../colors';
@use '../../mixins';
@use '../../z-indices';

.overlay {
  @include overlay.overlay();
  position: relative;
  z-index: z-indices.$dialogIndex;
}

.dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 400px;
  padding: variables.$base-spacing * 5;
  background-color: colors.$white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
}

.errorHeader {
  margin: 0px 0 20px 0;
  font-family: fonts.$font-family-strong;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  color: colors.$red;
}

.errorFooter {
  margin: 20px 0 0 0;

  display: flex;
  justify-content: flex-end;

  @include mixins.spaced-horizontal(20px);
}
