@use '../../../accessibility';
@use '../../../variables';
@use '../../../colors';

.toggleContainer {
  display: inline-flex;
  align-items: center;
}

.toggle {
  @include accessibility.visually-hidden;
}

.toggleLabel {
  position: relative;
  display: flex;
  align-items: flex-start;
}
