@use '../../../variables';
@use '../../../colors';
@use '../../../fonts';
@use '../../../breakpoints';

.container {
  display: block;
  position: relative;
}

.input {
  box-sizing: border-box;
  appearance: none;
  border: variables.$border;
  border-radius: variables.$border-radius;
  padding: variables.$base-spacing * 2 variables.$base-spacing * 2;
  padding-right: variables.$base-spacing * 9;
  width: 100%;
  font-family: fonts.$font-family-regular;
  font-size: fonts.$font-size-regular;
  height: variables.$base-spacing * 10;
  color: colors.$dark-blue;
  background: url(./icons/arrow.svg) no-repeat;
  background-color: colors.$white;
  background-position: right variables.$base-spacing * 3.5 top 50%;

  &.error {
    border-color: colors.$red-new;
    color: colors.$red-new;
  }

  &[readonly] {
    cursor: default;
  }
}
