@font-face {
  font-family: 'NLRTM-Bold';
  src: url('/assets/fonts/NLRTM-Bold.eot');
  src: url('/assets/fonts/NLRTM-Bold.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/NLRTM-Bold.otf') format('otf'),
    url('/assets/fonts/NLRTM-Bold.woff2') format('woff2'), url('/assets/fonts/NLRTM-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NLRTM-Medium';
  src: url('/assets/fonts/NLRTM-Medium.eot');
  src: url('/assets/fonts/NLRTM-Medium.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/NLRTM-Medium.otf') format('otf'),
    url('/assets/fonts/NLRTM-Medium.woff2') format('woff2'), url('/assets/fonts/NLRTM-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NLRTM-Regular';
  src: url('/assets/fonts/NLRTM-Regular.eot');
  src: url('/assets/fonts/NLRTM-Regular.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/NLRTM-Regular.otf') format('otf'),
    url('/assets/fonts/NLRTM-Regular.woff2') format('woff2'), url('/assets/fonts/NLRTM-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

$font-family-strong: 'NLRTM-Bold', 'Trebuchet MS', Arial, sans-serif;
$font-family-medium: 'NLRTM-Medium', 'Trebuchet MS', Arial, sans-serif;
$font-family-regular: 'NLRTM-Regular', 'Trebuchet MS', Arial, sans-serif;

$font-size-small: 0.75rem;
$font-size-regular: 0.875rem;
$font-size-medium: 1rem;
$font-size-large: 1.25rem;

$fs-8: 0.5rem;
$fs-10: 0.625rem;
$fs-12: 0.75rem;
$fs-14: $font-size-regular;
$fs-16: $font-size-medium;
$fs-18: $font-size-large;
$fs-20: 1.25rem;
$fs-22: 1.375rem;
$fs-24: 1.5rem;
