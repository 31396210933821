@use '../../../fonts';
@use '../../../colors';
@use '../../../variables';

$horizontalSpacing: variables.$base-spacing * 5;
$verticalSpacing: variables.$base-spacing * 3;
$button-color: colors.$light-blue;
$button-hover-color: colors.$medium-blue;
$button-disabled-color: colors.$disable-grey-new;
$button-label-color: colors.$white;
$button-secondary-border-width: 1px;

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 3px;
  background-color: $button-color;
  cursor: pointer;
  color: $button-label-color;
  padding: $verticalSpacing $horizontalSpacing;

  &:focus,
  &:hover {
    background-color: $button-hover-color;
  }

  &:disabled {
    background-color: $button-disabled-color;
  }

  > * + * {
    margin-left: $horizontalSpacing / 2;
  }
}

.label {
  font-family: fonts.$font-family-strong;
  font-size: fonts.$font-size-regular;
}

.icon {
  font-size: fonts.$font-size-medium;
  font-weight: bold;
}

.buttonSecondary {
  border: $button-secondary-border-width solid colors.$light-blue;
  padding: calc(#{$verticalSpacing} - #{$button-secondary-border-width}) $horizontalSpacing;
  color: colors.$light-blue;
  background: colors.$white;

  &:focus,
  &:hover {
    border-color: colors.$dark-blue;
    color: colors.$white;
    background: colors.$dark-blue;
  }

  &:disabled {
    color: $button-disabled-color;
    background-color: $button-disabled-color;
  }
}
