@use '../node_modules/react-redux-toastr/src/styles/index';
@use './colors';
@use './fonts';
@use './variables';

html,
body {
  margin: 0;
  padding: 0;
  border-width: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  color: colors.$dark-blue;
  background-color: colors.$page-background;
}

html,
body,
#root {
  height: 100%;
}

body {
  font-family: fonts.$font-family-regular;
  font-size: fonts.$font-size-regular;
}

button,
input,
textarea,
select {
  &:focus {
    outline: variables.$default-outline;
  }
}

.vp-toaster {
  .toastr {
    transition: all 0.2s;
    border-radius: 0;

    &.rrt-success {
      background-color: colors.$light-blue;
    }
  }
}
