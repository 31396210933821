@use '../../../colors';
@use '../../../fonts';
@use '../../../variables';

$switchContainerWidth: 50px;
$switchContainerHeight: 20px;
$switchMargin: 2px;
$switchHorizontalTextPadding: 6px;
$switchDotWithAndHeight: $switchContainerHeight - ($switchMargin * 2);

@mixin toggleTheme(
  $backgroundColorOff,
  $backgroundColorOn,
  $textColorOff,
  $textColorOn,
  $dotColorOff,
  $dotColorOn,
  $disabledColor,
  $borderOff: none,
  $borderOn: none
) {
  position: relative;
  vertical-align: middle;
  width: $switchContainerWidth;
  height: $switchContainerHeight;
  border-radius: 999px;
  transition: background 0.4s ease-in-out;
  border: $borderOff;

  &:not(.disabled) {
    cursor: pointer;
    background-color: $backgroundColorOff;
  }

  &.disabled {
    background-color: $disabledColor;
  }

  &:hover,
  :focus + & {
    outline: variables.$default-outline;
    outline-radius: 999px;
    -moz-outline-radius: 999px;
  }

  .toggleText {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: $switchHorizontalTextPadding;
    padding: 0;
    margin: 0;
    line-height: $switchContainerHeight + (2 * $switchMargin);
    font-family: fonts.$font-family-strong;
    color: $textColorOff;
    font-size: fonts.$font-size-small;
  }

  &::after {
    content: '';
    float: left;
    width: $switchDotWithAndHeight;
    height: $switchDotWithAndHeight;
    border-radius: 999px;
    transition: transform 0.4s cubic-bezier(1, 0, 0, 1);
    pointer-events: none;
    margin-top: $switchMargin;
    margin-left: $switchMargin;
    transform: translate3d(0, 0, 0);
    background-color: $dotColorOff;
  }

  &.checked {
    background-position: 0 0;

    &::after {
      -webkit-transform: translate3d($switchContainerHeight, 0, 0);
      transform: translate3d($switchContainerWidth - $switchContainerHeight, 0, 0);
    }

    .toggleText {
      left: $switchHorizontalTextPadding;
    }

    &:not(.disabled) {
      background-color: $backgroundColorOn;
      border: $borderOn;

      .toggleText {
        color: $textColorOn;
      }

      &::after {
        content: '';
        background-color: $dotColorOn;
      }
    }
  }
}
