@use '../../fonts';
@use '../../variables';
@use '../../colors';

$header-height: variables.$primary-menu-height;
$border-color: colors.$theme-border-color-new;
$button-hover-color: colors.$broken-white;
$background-color: colors.$white;
$font-color: colors.$dark-blue;

.button {
  background-color: $background-color;
  color: $font-color;
  border-left: solid 1px $border-color;
  height: $header-height;

  &:focus,
  &:hover {
    background-color: $button-hover-color;

    .label {
      text-decoration: underline;
    }
  }
}

.label {
  font-family: fonts.$font-family-regular;
  font-size: fonts.$font-size-small;
}
