@use '../../z-indices';
@use '../../colors';
@use '../../breakpoints';

$modal-max-width: breakpoints.$maxPageWidth;
$modal-max-narrow-width: 30rem;

.modalContainer {
  display: flex;
  z-index: z-indices.$planningEntryModalIndex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  background-color: rgba(colors.$black, 0.6);
}

.modal {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  margin: 0 auto auto;
  width: auto;
  max-width: $modal-max-width;
  max-height: 100vh;

  cursor: auto;
  background-color: colors.$white;
}

.modalNarrow {
  margin: auto;
  max-width: $modal-max-narrow-width;
}
