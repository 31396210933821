@use '../../variables';
@use './variables' as localVars;
@use '../../spaced';
@use '../../fonts';
@use '../../mixins';
@use '../../z-indices';
@use "../../colors";

.tooltip {
  position: relative;
  display: inline-flex;
  justify-content: center;
  margin: 0 variables.$base-spacing;
}

.popup {
  position: absolute;
  box-sizing: border-box;
  font-family: fonts.$font-family-regular;
  font-size: fonts.$font-size-regular;
  color: colors.$dark-grey;
  z-index: z-indices.$tooltipIndex;

  // top
  &.topCenter,
  &.topLeft,
  &.topRight {
    bottom: 100%;
  }

  // bottom
  &.bottomLeft,
  &.bottomRight {
    top: 100%;

    @include mixins.fadeIn();
  }

  // left
  &.topLeft,
  &.bottomLeft {
    right: -#{localVars.$tooltipHelpIconSize / 2}px !important;
  }

  // right
  &.topRight,
  &.bottomRight {
    left: -#{localVars.$tooltipHelpIconSize / 2}px !important;
  }

  // center
  &.topCenter,
  &.bottomCenter {
    align-items: center;
  }
}

.helpIconWrapper {
  width: 100%;
  transition: color 0.3s;
}

.helpIconWrapper:hover {
  cursor: pointer;
  color: colors.$dark-blue;
}

.helpIcon {
  vertical-align: middle;
  font-size: #{localVars.$tooltipHelpIconSize}px;
}

.closeIconWrapper {
  order: 1;
  padding-left: variables.$base-spacing * 2;
}

.closeIcon {
  vertical-align: middle;
  font-size: 18px;
  color: colors.$dark-blue;
  padding: spacing(2);

  &:hover {
    cursor: pointer;
  }
}

.triangleClip {
  // top
  &.topCenter,
  &.topLeft,
  &.topRight {
    order: 1;
  }
  // center
  &.topCenter,
  &.bottomCenter {
    margin: 0 !important;
  }
}

.triangle {
  // top
  &.topCenter,
  &.topLeft,
  &.topRight {
    margin-top: -10px !important;
  }
}
