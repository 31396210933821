@import '../../z-indices';

.dropoutMenu {
  position: relative;

  &.active {
    z-index: $dropoutIndex;
  }
}

.dropoutMenuOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.3);
  z-index: $dropoutIndex - 1;
}
