@use '../../variables';
@use '../../breakpoints';
@use '../../fonts';
@use '../../colors';

$cell-spacing: variables.$base-spacing * 2;

.berthVisitsTable {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  text-align: left;
  font-size: fonts.$font-size-small;
  color: colors.$dark-blue;
  background: colors.$white;

  @media screen and (max-width: breakpoints.$sm-max) {
    table,
    tbody {
      display: block;
    }
  }
}
