@mixin overlay() {
  &:not(.active) {
    display: none;
  }

  &.active {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
}