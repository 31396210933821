@use '../../../fonts';
@use '../../../colors';
@use '../../../variables';

$horizontal-spacing: variables.$base-spacing * 3;
$vertical-spacing: variables.$base-spacing * 2;

.textarea {
  box-sizing: border-box;
  border: variables.$border;
  border-radius: variables.$border-radius;
  padding: $vertical-spacing $horizontal-spacing;
  width: 100%;
  font-family: fonts.$font-family-regular;
  font-size: fonts.$font-size-regular;
  color: colors.$dark-blue;

  &:focus {
    border-color: colors.$very-light-blue;
  }

  &:disabled {
    opacity: 0.5;
  }

  &.error {
    border-color: colors.$red-new;
    color: colors.$red-new;
  }
}
