@mixin visually-hidden {
  position: absolute;
  overflow: hidden;
  height: 1px;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  word-wrap: normal;
}

@mixin visually-hidden-until-focus {
  &:not(:focus) {
    @include visually-hidden();
  }
}
