@mixin spaced-horizontal($spacing, $padding: 0) {

  & > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  & > *:first-child {
    margin-left: $padding;
  }

  & > *:not(:first-child) {
    margin-left: $spacing / 2;
  }

  & > *:last-child {
    margin-right: $padding;
  }

  & > *:not(:last-child) {
    margin-right: $spacing / 2;
  }
}

@mixin spaced-vertical($spacing) {

  & > * {
    margin-left: 0;
    margin-right: 0;
  }

  & > *:first-child {
    margin-top: 0;
  }

  & > *:not(:first-child) {
    margin-top: $spacing;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  & > *:not(:last-child) {
    margin-bottom: $spacing;
  }
}

@mixin spaced-vertical-no-margin-collapse($spacing) {
  @include spaced-vertical($spacing / 2);
}
