@use '../../colors';
@use '../../fonts';

.button {
  background-color: colors.$yellow;
  color: colors.$dark-blue;
  font-family: fonts.$font-family-strong;
  font-size: fonts.$font-size-regular;
  line-height: 14px;
  padding: 15px;
  transition: all 0.2s ease;
  cursor: pointer;
  border-radius: 4px;
  border-width: 0;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);

  &:hover {
    background-color: colors.$dark-blue;
    color: colors.$white;
  }
}
