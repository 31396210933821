@use '../../../colors';
@use '../../../breakpoints';
.menu {
  box-sizing: border-box;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
  margin: 0;
  padding: 0;
  max-width: 100%;
  width: 100%;
  list-style-type: none;
  max-height: 230px;
  overflow: auto;
  background: colors.$white;

  &.open {
    border: 1px solid colors.$light-blue;
  }

  @media screen and (min-width: breakpoints.$md-min) {
    min-width: 100%;
    width: max-content;
  }
}
