@use '../../variables';
@use '../../colors';
@use '../../fonts';

.sideMenuItem {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: variables.$base-spacing * 2;
  padding: 14px 10px;
  width: 100%;
  text-decoration: none;
  background: none;

  &:focus,
  &:hover {
    background-color: colors.$very-light-blue-hover;
  }

  + .sideMenuItem {
    border-top: 1px solid colors.$lighter-grey;
  }
}

.sideMenuText {
  font-family: fonts.$font-family-regular;
  font-size: fonts.$font-size-medium;
  color: colors.$dark-blue;
}

.sideMenuIcon {
  transition: all 0.2s;
  transform: rotate(-90deg);
  fill: colors.$light-blue;

  :focus > &,
  :hover > & {
    transform: translateX(0.25rem) rotate(-90deg);
  }
}
