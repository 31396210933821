@use './SideMenu.module';
@use '../../colors';
@use '../../variables';
@use '../../mixins';

/*
  Corner close button should have same height as $primary-menu-height.
  We want to show the button when the side menu opens in the exact same place
  as where the hamburguer menu was, thus a user can 'open' and 'close' the menu
  in clicking in the same area.
*/
@mixin cornerCloseButtonSize($size) {
  height: $size;
  width: $size;
  line-height: $size;

  .cross {
    line-height: $size;
  }
}

$sideMenuCloseButtonSize: variables.$primary-menu-height;

.sideMenuHeader {
  flex-grow: 0;
  flex-shrink: 0;

  border-bottom: 1px solid colors.$lighter-grey;
  height: variables.$primary-menu-height;

  display: flex;
  flex-direction: row;
  align-items: center;

  .closeIconWrapper {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .headerLogo {
    flex-grow: 1;
    flex-shrink: 1;
    text-align: center;
  }
}

.closeIcon {
  @include cornerCloseButtonSize($sideMenuCloseButtonSize);
  @include mixins.clickable();
  border: 0;
  background-color: colors.$light-blue;
  text-align: center;
  color: colors.$white;

  .icon {
    line-height: $sideMenuCloseButtonSize;
  }

  &.top {
    position: absolute;
    top: 0;
  }

  &.right {
    position: absolute;
    right: 0;
  }
}
