@keyframes spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin animation($name, $duration, $timing, $iteration) {
  animation: $name $duration $timing $iteration;
}
