@use '../../variables';
@use '../../fonts';
@use '../../colors';

.shipIdContainer {
  display: block;
  font-size: fonts.$font-size-small;
  color: colors.$dark-grey;

  > * + * {
    margin-left: variables.$base-spacing * 2;
  }
}

.menu {
  max-height: 452px;
}
