@use '../../accessibility';
@use '../../breakpoints';
@use '../../variables';
@use '../../colors';
@use '../../fonts';

$berth-visits-header-button-padding-sm: variables.$base-spacing * 3;
$berth-visits-header-button-padding-md: variables.$base-spacing * 3 variables.$base-spacing * 4;
$berth-visits-header-button-padding-lg: variables.$base-spacing * 3 variables.$base-spacing * 5;

.berthVisitsHeader {
  .selectAllCheckbox {
    &:hover + .selectAllLabel::before,
    &:focus + .selectAllLabel::before {
      border-color: colors.$dark-blue;
    }

    &:checked + .selectAllLabel::before {
      border-color: colors.$dark-blue;
      background: colors.$dark-blue;
    }
  }

  .selectAllLabel {
    padding: $berth-visits-header-button-padding-sm;
    padding-left: variables.$base-spacing * 4;

    @media screen and (min-width: breakpoints.$md-min) {
      padding: $berth-visits-header-button-padding-md;
    }

    @media screen and (min-width: breakpoints.$lg-min) {
      padding: $berth-visits-header-button-padding-lg;
    }
  }

  .selectAllIcon {
    left: variables.$base-spacing * 4;

    @media screen and (min-width: breakpoints.$lg-min) {
      left: variables.$base-spacing * 5;
    }
  }

  .cancelButtonTextInner {
    @media screen and (max-width: breakpoints.$xs-max) {
      display: none;
    }
  }

  .deleteButton {
    display: inline-flex;
    border-left: 1px solid colors.$very-light-blue;
    padding: $berth-visits-header-button-padding-sm;
    line-height: 1.25rem;

    @media screen and (min-width: breakpoints.$md-min) {
      padding: $berth-visits-header-button-padding-md;
    }

    @media screen and (min-width: breakpoints.$lg-min) {
      padding: $berth-visits-header-button-padding-lg;
    }
  }

  .deleteButtonLabel {
    font-family: fonts.$font-family-regular;
  }

  .filterButton {
    border-radius: 0;
    border-left: 1px solid rgba(colors.$white, 0.4);
    padding: variables.$base-spacing * 3;
    color: colors.$white;
    background: none;

    &:focus,
    &:hover {
      background: colors.$medium-blue;
    }

    > * + * {
      margin-left: 0;
    }

    @media screen and (min-width: breakpoints.$lg-min) {
      border-right: 1px solid rgba(colors.$white, 0.4);
    }
  }

  .filterButtonIcon {
    font-size: 1.25rem;
  }

  .filterButtonActive {
    color: colors.$white;
    background: colors.$light-blue;

    &:focus,
    &:hover {
      color: colors.$white;
      background: colors.$medium-blue;
    }
  }

  .filterButtonLabel {
    @include accessibility.visually-hidden;
  }

  .dropoutHeaderInputGroup {
    + .dropoutHeaderInputGroup {
      margin-top: variables.$base-spacing * 5;
    }

    > * {
      display: block;
      margin: 0;

      + * {
        margin-top: variables.$base-spacing * 3;
      }
    }
  }
}

.berthVisitsHeaderInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.berthVisitsHeaderLeft,
.berthVisitsHeaderRight {
  display: flex;
  align-items: center;
}

.dropoutHeader {
  font-family: fonts.$font-family-strong;
  font-size: fonts.$font-size-medium;
  font-weight: normal;
  line-height: 1.125rem;
}
