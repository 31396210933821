$yellow: #ffd800;
$yellow-light: #fcf6d8;
$red: #d91c33;
$disable-gray: #d5d1cd;
$theme-border-color: #eaeaea;

// New colors
$very-light-blue: #73bee6;
$very-light-blue-hover: #e0eef4;
$light-blue: #0693ca;
$medium-blue: #077ab7;
$dark-blue: #001f4b;
$black: black;
$red-new: #db392a;
$chestnut-rose: #cf594e;
$green: #5d9e16;
$apple: #46be41;
$white: white;
$dark-grey-new: #b8c7cc;
$disable-grey-new: #ccc8c5;
$medium-grey: #cccccc;
$lighter-grey: #f2f2f2;
$light-grey: #d8d8d8;
$gray: #ececec;
$dark-grey: #777;
$broken-white: #f8f8f8;
$page-background: #f5f5f5;
$sea-buckthorn: #f7ae33;

$label-actual: $green;
$label-estimate: #b8c7cc;

$theme-border-color-new: #f2f1f1;

$seashell: #f2f1f1;
$concrete: #f2f2f2;
$pale-sky: #696e7d;
$cloud-grey: #ccc8c5;

// Zeplin
$powder-blue: #c2deea;
$teal-blue: #074769;
$chestnut-rose: #cf594e;
$apple: #46be41;
