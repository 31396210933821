@use '../../../colors';
@use '../../../variables';
@use '../../../fonts';

$horizontal-spacing: variables.$base-spacing * 3;
$vertical-spacing: variables.$base-spacing * 2;

.maskedInput {
  box-sizing: border-box;
  font-family: fonts.$font-family-regular;
  font-size: fonts.$font-size-regular;
  color: colors.$dark-blue;
  padding: $vertical-spacing $horizontal-spacing;
  height: calc(#{variables.$base-spacing * 10} - 2px);

  &:focus {
    border-style: none;
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
  }

  &.error {
    color: colors.$red-new;
  }
}
