@use '../../colors';

.wrapper {
  display: block;
}

.label {
  margin-bottom: 2px;
  color: colors.$dark-grey;
}

.value {
  color: colors.$dark-blue;
}
