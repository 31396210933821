@use '../../spaced';
@use '../../colors';
@use '../../fonts';
@use '../../mixins';
@use '../../variables';

$item-spacing: variables.$base-spacing * 2;
$distance-footer-to-bottom: variables.$base-spacing * 5;
$user-icon-size: 46px;

.sideMenuFooter {
  flex-grow: 0;
  flex-shrink: 0;

  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include mixins.spaced-vertical($item-spacing);
  padding-bottom: $distance-footer-to-bottom;
}

.userIcon {
  flex: 0 0 $user-icon-size;
  height: $user-icon-size;
  width: $user-icon-size;
  background-size: $user-icon-size;
  border-radius: $user-icon-size;
  background-color: sandybrown;
}

.userProperty {
  font-family: fonts.$font-family-strong;
  font-size: 16px;
}

.logout {
  font-family: fonts.$font-family-regular;
  font-size: fonts.$font-size-medium;

  &:hover {
    text-decoration: underline;
  }
}
