@use '../../colors';
@use '../../fonts';
@use '../../variables';
@use '../../spaced';
@use '../../breakpoints';

$primary-menu-background-color: colors.$white;
$font-size: fonts.$font-size-medium;
$header-height: variables.$primary-menu-height;
$large-header-height: 3.75rem;

.header {
  position: relative;
  font-family: fonts.$font-family-strong;
  background-color: $primary-menu-background-color;
}

.headerInner {
  display: flex;
  justify-content: space-between;
}

.left {
  @include spaced.spaced-horizontal(15px);
  display: flex;
  align-items: center;
}

.right {
  display: flex;
  align-items: center;
}

.hamburger {
  :global(.material-icons) {
    font-size: $font-size;
  }

  height: $font-size;
  width: $font-size;
  padding: ($header-height - $font-size) / 2;
  cursor: pointer;
  background-color: colors.$white;
  color: colors.$dark-blue;
  border-right: 1px colors.$light-grey solid;
}

.addButton {
  position: relative;
  height: $header-height;
  background: none;
  color: colors.$dark-blue;
  border-radius: 0;

  &:not(:first-child) {
    ::before {
      content: '';
      width: 2px;
      height: fonts.$font-size-medium;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto 0;
      background-color: colors.$dark-blue;
    }
  }

  &:hover {
    background: none;
  }

  &Icon {
    background: colors.$light-blue;
    color: colors.$white;
    border-radius: 50%;
    padding: 3px;
  }
}

.secondaryHeader {
  color: colors.$white;
  background: colors.$light-blue;
}
