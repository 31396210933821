@use '../../../spaced';
@use '../../../fonts';
@use '../../../variables';
@use '../../../colors';
@use '../../../breakpoints';
@use '../../../mixins';

$header-height: variables.$primary-menu-height;
$spacing: variables.$base-spacing * 5;
$title-color: colors.$light-blue;
$header-border-color: colors.$theme-border-color-new;

.modalHeader {
  flex: 0 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $header-height;
  width: 100%;
  border-bottom: solid 1px $header-border-color;
}

.title {
  font-family: fonts.$font-family-strong;
  font-size: fonts.$font-size-medium;
  font-weight: normal;
  color: $title-color;
  text-transform: uppercase;
  padding: $spacing;

  @media screen and (min-width: breakpoints.$md-min) {
    font-size: fonts.$font-size-large;
  }
}
