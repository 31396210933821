@use '../../../fonts';
@use '../../../colors';
@use '../../../variables';

.label {
  width: 100%;
  font-family: fonts.$font-family-strong;
  font-size: fonts.$font-size-regular;
  color: colors.$dark-blue;
}
