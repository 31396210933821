@use '../../breakpoints';
@use '../../variables';
@use '../../colors';

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid colors.$gray;
  padding: variables.$base-spacing * 2 variables.$base-spacing * 5;

  > * + * {
    margin-left: variables.$base-spacing * 2;
  }

  @media screen and (min-width: breakpoints.$md-min) {
    padding: variables.$base-spacing * 5;

    > * + * {
      margin-left: variables.$base-spacing * 3;
    }
  }
}
