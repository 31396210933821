@use "sass:list";
@use './colors';
@use './animations';

@mixin clickable {
  cursor: pointer;
}

@mixin ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin square($size) {
  width: $size;
  height: $size;
}

@mixin spaced($spacing, $extra: 0) {
  $margin-top: 0;
  $margin-right: 0;
  $margin-bottom: 0;
  $margin-left: 0;
  @if list.length($extra) == 1 {
    $margin-top: list.nth($extra, 1);
    $margin-right: list.nth($extra, 1) - $spacing / 2;
    $margin-bottom: list.nth($extra, 1);
    $margin-left: list.nth($extra, 1) - $spacing / 2;
  } @else {
    @if list.length($extra) == 2 {
      $margin-top: list.nth($extra, 1);
      $margin-right: list.nth($extra, 2) - $spacing / 2;
      $margin-bottom: list.nth($extra, 1);
      $margin-left: list.nth($extra, 2) - $spacing / 2;
    } @else {
      @if list.length($extra) == 4 {
        $margin-top: list.nth($extra, 1);
        $margin-right: list.nth($extra, 2) - $spacing / 2;
        $margin-bottom: list.nth($extra, 3);
        $margin-left: list.nth($extra, 4) - $spacing / 2;
      }
    }
  }

  margin-top: $margin-top;
  margin-bottom: $margin-bottom;

  & > *:not(:first-child) {
    margin-left: $spacing / 2;
  }

  & > *:not(:last-child) {
    margin-right: $spacing / 2;
  }
}

@mixin spaced-horizontal($spacing) {
  & > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  & > *:first-child {
    margin-left: 0;
  }

  & > *:not(:first-child) {
    margin-left: $spacing / 2;
  }

  & > *:last-child {
    margin-right: 0;
  }

  & > *:not(:last-child) {
    margin-right: $spacing / 2;
  }
}

@mixin spaced-vertical($spacing) {
  & > * {
    margin-left: 0;
    margin-right: 0;
  }

  & > *:first-child {
    margin-top: 0;
  }

  & > *:not(:first-child) {
    margin-top: $spacing;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  & > *:not(:last-child) {
    margin-bottom: $spacing;
  }
}

@mixin round($diameter) {
  height: $diameter;
  width: $diameter;
  border-radius: $diameter / 2;
}

@mixin circumference($size, $borderColor) {
  @include round($size);
  border: 2px $borderColor solid;
  box-sizing: border-box;
}

@mixin spinner($size, $margin, $backgroundBorderColor: colors.$medium-grey, $foregroundBorderColor: colors.$black) {
  @include circumference($size, $backgroundBorderColor);
  border-bottom-color: $foregroundBorderColor;
  @include animations.animation(spinner-rotate, 0.75s, linear, infinite);
  margin: $margin auto;
}

@mixin centerWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin fadeIn {
  opacity: 0;
  animation: fade-in 0.7s ease-out 0.3s forwards;
}
