@use '../../../fonts';
@use '../../../colors';
@use '../../../variables';

.errorMessage {
  font-family: fonts.$font-family-regular;
  font-size: fonts.$font-size-small;
  color: colors.$dark-blue;
}

.asterisk {
  color: colors.$red-new;
}
