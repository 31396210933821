@use '../../variables';
@use './variables' as localVars;
@use '../../colors';

$tooltip-drop-shadow: 0 0 2px 1px rgba(colors.$black, 0.2);

.tooltipMenu {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100vw;
  max-width: 15rem;

  .triangleClip {
    flex-grow: 0;
    flex-shrink: 0;
    height: #{localVars.$triangleSideSize}px;
    width: #{localVars.$triangleWidth}px;
    overflow: hidden;

    .triangle {
      margin: 0 auto;
      width: #{localVars.$triangleSideSize}px;
      height: #{localVars.$triangleSideSize}px;
      background-color: colors.$white;
      transform: translateY(50%) rotate(45deg);
      box-shadow: $tooltip-drop-shadow;
    }
  }

  .content {
    flex: 1 1 auto;
    overflow: hidden;

    display: flex;
    flex-direction: row;
    align-items: stretch;

    padding: variables.$base-spacing * 2 variables.$base-spacing * 3;
    background-color: colors.$white;
    box-shadow: $tooltip-drop-shadow;
  }
}
