@use '../../variables';
@use '../../colors';
@use '../../fonts';
@use '../../animations';

@mixin spinner($size, $margin) {
  width: $size;
  height: $size;
  border: 2px colors.$medium-grey solid;
  border-bottom-color: black;
  border-radius: $size;
  @include animations.animation(spinner-rotate, 0.75s, linear, infinite);

  margin: $margin auto;

  background-image: url("data:image/svg+xml;utf8,<svg width='21' height='16' xmlns='http://www.w3.org/2000/svg'><path fill='black' d='m2.126,6.001l-2.126,2.116l7.066,7.097l13.06,-13.096l-2.125,-2.118l-10.933,10.964l-4.942,-4.963z'/></svg>");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0%;
}

@mixin spinnerWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loader {
  .spinnerWrapper {
    @include spinnerWrapper;
    font-family: fonts.$font-family-strong;
    font-size: fonts.$font-size-medium;
    color: black;
  }

  .spinner {
    @include spinner(40px, 10px);
  }

  &.finished {
    .spinner {
      border-color: #000;
      animation: none;
      background-size: 50%;
      transition: background-size 0.5s;
    }
  }

  &.error {
    .spinner {
      background-image: url("data:image/svg+xml;utf8,<svg fill='#000000' height='48' viewBox='0 0 24 24' width='48' xmlns='http://www.w3.org/2000/svg'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      border-color: #000;
      animation: none;
      background-size: 50%;
      transition: background-size 0.5s;
    }
  }
}
