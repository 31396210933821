@use '../../variables';
@use '../../breakpoints';
@use '../../colors';
@use '../../fonts';
@use '../Form/Checkbox/Checkbox.module';

$column-gap-width: variables.$base-spacing * 5;
$column-width: 210px;
$breakpoint-extra-md: 65rem; // 1040px
$breakpoint-form-md-max: $breakpoint-extra-md - 0.01;

.form {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: breakpoints.$md-min) {
    justify-content: space-between;
    flex-direction: row;
  }

  @media screen and (min-width: breakpoints.$sm-max) and (max-width: $breakpoint-form-md-max) {
    display: flex;
    flex-direction: column;
  }
}

.settings {
  > * + * {
    margin-top: variables.$base-spacing * 5;
  }

  display: flex;
  flex-direction: column;
  background-color: colors.$lighter-grey;
  padding: variables.$base-spacing * 5;
}

.bertVisitEntry {
  > * + * {
    margin-top: variables.$base-spacing * 5;
  }
  padding: variables.$base-spacing * 5;

  @media screen and (min-width: breakpoints.$md-min) and (max-width: $breakpoint-form-md-max) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: $column-gap-width;
  }

  @media screen and (min-width: $breakpoint-extra-md) {
    display: grid;
    grid-template-columns: repeat(3, $column-width);
    grid-column-gap: $column-gap-width;
  }
}

.formColumn {
  > * + * {
    margin-top: variables.$base-spacing * 5;
  }

  @media screen and (min-width: breakpoints.$md-min) {
    margin-top: 0;
  }
}

.remarkField {
  grid-column-start: 2;
  grid-column-end: 4;
}

.title {
  font-family: fonts.$font-family-strong;
  font-size: fonts.$font-size-medium;
}

.batchEntrySettings {
  @media screen and (min-width: breakpoints.$md-min) and (max-width: $breakpoint-form-md-max) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: $column-gap-width;
  }
}

.batchPreFillFields {
  padding-left: variables.$base-spacing * 7;

  > * {
    margin-top: variables.$base-spacing * 5;
  }

  @media screen and (min-width: breakpoints.$md-min) and (max-width: $breakpoint-form-md-max) {
    > * {
      margin-top: 0;
    }
    grid-column: 2 / span 2;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $column-gap-width;
    padding-left: 0;
  }
}
