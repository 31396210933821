@use '../../../colors';

.inputLabel {
  display: flex;
  align-items: center;
}

.labelText {
  margin-bottom: 0.25rem;
}

.asterisk {
  color: colors.$red-new;
}

.disabled {
  opacity: 0.5;
}
