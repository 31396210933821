@use './colors';

// ## Base spacing unit ##
$base-spacing: 0.25rem;

// ## Primary menu ##
$primary-menu-height: 3.125rem; // 50px

// ## Secondary menu ##
$secondary-menu-height: 2.5rem; // 40px

// ## Orientation table cell
$table-body-orientation-field: 75px;

// ## Bollards table cell
$table-body-bollards-field: 150px;

$default-outline: 3px solid rgba(colors.$light-blue, 0.5);

$border: 1px solid colors.$disable-grey-new;
$border-radius: 2px;
