@use '../../colors';
@use '../../variables';
@use '../../mixins';

$spinnerSizeSmall: 1em;
$spinnerSizeMedium: 2em;
$spinnerSizeLarge: 3em;

@mixin withSpinnerSize($spinnerSize) {
  width: $spinnerSize;
  height: $spinnerSize;

  .lightBackgroundSpinner {
    @include mixins.spinner($spinnerSize, 0);
  }
}

.loaderInline {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  &.small {
    @include withSpinnerSize($spinnerSizeSmall);
  }

  &.medium {
    @include withSpinnerSize($spinnerSizeMedium);
  }

  &.large {
    @include withSpinnerSize($spinnerSizeLarge);
  }

  .spinnerWrapper {
    @include mixins.centerWrapper;
  }
}
