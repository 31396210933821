@use "../../fonts";
@use "../../colors";
@use "../../mixins";

.background {
  height: 100%;
  background: url('/assets/images/login-bg.jpg') center center / cover no-repeat fixed;
}

.loginBox {

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translate(0, 70px);

  width: 260px;
  padding: 40px;

  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;

  text-align: center;

  @include mixins.spaced-vertical(60px);

  .logoText {
    font-family: fonts.$font-family-strong;
    font-size: 30px;
    color: colors.$dark-blue;
    margin-top: -5px;
  }

  .actions {
    @include mixins.spaced-vertical(15px);

    .requestAccess {
      font-family: fonts.$font-family-medium;
      font-size:fonts.$font-size-regular;
      color: black;

      .question {
        font-weight: bold;
      }

      .answer {
        text-decoration: underline;
        color: black;
      }

    }

  }
}
