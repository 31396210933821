@use '../../../colors';
@use '../../../variables';
@use '../../../fonts';

$horizontal-spacing: variables.$base-spacing * 3;
$vertical-spacing: variables.$base-spacing * 2;

.dateTimeInputWithToggle {
  display: flex;
  align-items: center;
  border: variables.$border;
  border-radius: variables.$border-radius;

  &:focus-within {
    border-color: colors.$very-light-blue;
    outline: variables.$default-outline;
  }

  &.error {
    border-color: colors.$red-new;
  }

  .dateInput {
    border: none;
    width: 65px;
    padding-right: 0;
  }

  .timeInput {
    border: none;
    width: 75px;
  }

  .toggle {
    margin: auto $horizontal-spacing auto auto;
  }
}
